<template>
  <div v-if="page && !loading">
    <simple-title
      v-if="appointmentsUnconfirmed"
      grid="row wrap justify-center"
      column="xs-12 s-12 m-10">
      <div style="width:100%;">
        <h1
          class="_text-center no-content"
          :style="globalTheme.colorBlack">
          {{page.pageTitle}}
        </h1>
        <rich-text-simple
          v-if="page.titleContent"
          :html="page.titleContent"
          class="_mtxs"/>
      </div>
    </simple-title>
    <app-section
      v-else
      class="_pb0 _ptm hide-print"
      grid="row wrap justify-center">
      <div
        v-if="appointmentsPayload.find(appt => appt.failedToSchedule)"
        column="xs-12 s-12"
        class="_text-center">
        <rich-text
          v-if="page.appointmentConfirmation.appointmentsFailedMessage"
          :html="page.appointmentConfirmation.appointmentsFailedMessage"
          class="_p0"
          modifier="body-copy _text-center"/>
      </div>
      <div
        v-else
        column="xs-12 s-12"
        class="_text-center">
        <h1 :style="globalTheme.colorBlack">
          <template v-if="appointmentsPayload.length > 1">
            {{page.appointmentConfirmation.titleMultipleAppointments}}
          </template>
          <template v-else>
            {{page.appointmentConfirmation.title}}
          </template>
        </h1>
        <rich-text
          v-if="page.appointmentConfirmation.message"
          :html="page.appointmentConfirmation.message"
          class="_p0"
          modifier="body-copy _text-center"/>
      </div>
    </app-section>
    <app-section
      v-if="appointmentsUnconfirmed"
      id="schedule_nav"
      class="_pts _pbs bg-white"
      grid="justify-center">
      <div id="schedule_nav_scroll_anchor"></div>
      <a
        v-for="(route,i) in routes.slice(0, 3)"
        :key="i"
        class="_text-center schedule-step-nav"
        :class="{disabled: i > currentRouteIndex}"
        :style="i > currentRouteIndex ? globalTheme.colorBlack : globalTheme.colorPrimaryDark"
        :href="route.path"
        :tabindex="i > currentRouteIndex ? '-1' : undefined"
        column="m-4"
        @click.prevent="replaceRoute(route)">
        <span 
          class="step-count"
          :style="[i > currentRouteIndex ? globalTheme.backgroundColorBlack : globalTheme.backgroundColor, globalTheme.colorWhite]">{{i + 1}}</span>
        <p
          :style="i > currentRouteIndex ? globalTheme.colorBlack : globalTheme.colorPrimaryDark"
          class="_m0">{{route.name}}</p>
        <font-awesome-icon
          v-if="i !== routes.slice(0, 3).length - 1"
          :style="i >= currentRouteIndex ? globalTheme.colorBlack : globalTheme.color"
          :icon="['fas', 'caret-right']"
          class="caret"/>
      </a>
    </app-section>
    <app-section
      grid="justify-center"
      class="schedule-step-content _pts _pbm"
      :class="{widen: currentRouteIndex === 1}">
      <schedule-card
        :sidebar-column="sidebarGridWidthByRoute"
        :column="gridWidthByRoute" 
        class="_ptm _pbm _pls _prs">
        <router-view
          :value="appointmentsPayload"
          :localized-route="localizedRoute"
          :current-patient-index="currentPatientIndex"
          :provider="provider"
          :page="page"
          @setPatientIndex="setPatientIndex"/>
        <template v-slot:sidebar>
          <patient-list
            :labels="page.patientStatusLabels"
            :read-only="currentRouteIndex === 2"
            :current-patient-index="currentPatientIndex"
            :appointments-payload="appointmentsPayload"
            :current-route-index="currentRouteIndex"
            @setPatientIndex="setPatientIndex"/>
          <google-map-single-provider 
            remove-controls
            :provider="provider"
            class="directions-map" />
          <sidebar-content
            v-if="appointmentsUnconfirmed"
            :provider="provider"/>
          <confirmation-actions
            v-else
            :localized-route="localizedRoute"
            :labels="page.appointmentConfirmation"
            :provider="provider"/>
          <doctor-list
            v-if="provider.doctors.length && currentRouteIndex === 1"
            class="mobile until-tablet"
            :provider="provider"
            :doctors="provider.doctors"
            :labels="page.scheduleADate"/>
        </template>
      </schedule-card>
    </app-section>
    <app-section
      v-if="page.disclaimer"
      grid="justify-center"
      class="_pt0 disclaimer hide-print">
      <div :column="gridWidthByRoute">
        <rich-text
          :html="page.disclaimer"
          class="_p0"/>
      </div>
    </app-section>
  </div>
  <app-loader-section
    v-else-if="loading"
    whole-height />
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import GetPage from '@/mixins/GetPage'
  import SimpleTitle from '@sections/SimpleTitleSlotted.vue'
  import ScheduleCard from '@sections/Schedule/ScheduleCard.vue'
  import SidebarContent from '@sections/Schedule/SidebarContent.vue'
  import GoogleMapSingleProvider from '@molecules/GoogleMapSingleProvider.vue'
  import PatientList from '@organisms/Schedule/PatientList.vue'
  import ConfirmationActions from '@organisms/Schedule/ConfirmationActions.vue'
  import DoctorList from '@organisms/Schedule/DoctorList.vue'
  import RichTextSimple from '@global/RichTextSimple.vue'
  import ExamInformation from '@sections/Schedule/01-Exam-Information.vue'
  import PersonalInformation from '@sections/Schedule/03-Personal-Information.vue'
  import ScheduleDate from '@sections/Schedule/02-Schedule-Date.vue'
  import { scheduleViewChildRoutes } from '@/router.ts'
  import { SchedulePageLabels, UserCreatedAppointment, Provider, ContentStackImage, CurrentProvider } from '@/types'
  import { createAppointment, removeLeadingZero, isInViewport } from '@/ts/helpers/schedule.helpers.ts'
  import { isMock } from '@/../env.config.ts'
  import { eventBus } from '@/main'
  const name = 'schedule'
  export default defineComponent({
    name: name,
    components: { ExamInformation,PersonalInformation,ScheduleDate,SimpleTitle, ScheduleCard, GoogleMapSingleProvider, SidebarContent, PatientList, ConfirmationActions, DoctorList, RichTextSimple },
    mixins: [GetPage],
    props: {
      framed: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return { 
        currentPatientIndex: 0,
        cardKey:0,
        loading: true,
        provider: this.$store.state.currentProvider.currentProvider as CurrentProvider|undefined,
        appointmentsPayload: [createAppointment('', '')] as UserCreatedAppointment[],
        page: null as null | any
      }
    },
    computed: {
      appointmentsUnconfirmed(): boolean {
        return this.currentRouteIndex !== 3
      },
      currentRouteIndex(): number {
        return scheduleViewChildRoutes.findIndex(route => route.name === this.$route.name)
      },
      gridWidthByRoute(): string {
        switch (this.currentRouteIndex) {
        case 1:
          return 'xs-12 s-12'
        default:
          return 'xs-12 l-9'
        }
      },
      sidebarGridWidthByRoute(): string {
        switch (this.currentRouteIndex) {
        case 1:
          return 'xs-12 s-12 m-4'
        case 3:
          return 'xs-12 s-12 m-6'
        default:
          return 'xs-12 s-12 m-4 l-5'
        }
      },
      clientID(): string {
        return (this as any).$store.state.globals.microsite.clientId.toLowerCase()
      },
      selectedLanguage(): string {
        return (this as any).$store.state.locale.languageSelect.value
      },
      localizedRoute(): string {
        return `/${this.clientID}/${this.selectedLanguage}`
      },
      routes(): {name:string, path: string}[] {
        const contentStackRouteLabels = this.page.scheduleStepLabels || []
        const contentStackLabelKeys = Object.keys(contentStackRouteLabels)
        return scheduleViewChildRoutes.map((route, i) => {
          return i === 0 ? { 
            name: contentStackRouteLabels[contentStackLabelKeys[i]] || route.name,
            path: `schedule`
          }:{ 
            name: contentStackRouteLabels[contentStackLabelKeys[i]] || route.name,
            path: `schedule/${route.path}`
          }
        })
      } 
    },
    watch: {
      $route(to, from) {
        if (to.name !== from.name) {
          this.scrollIntoFocus()
        }
        this.$store.dispatch('responseMessages/clearServiceErrors')
        this.currentPatientIndex = 0
        setTimeout(() => {
          eventBus.$emit('iframeResizeEvent')
        }, 300)
      },
      selectedLanguage: {
        handler(){
        this.loadPageLabels()
        setTimeout(() => {
          window.location.reload()
        }, 100);
        },
        deep:true
      }
    },
    methods: {
      async loadPageLabels(): Promise<void> {
        await (this as any).getStackPageByName(name, (page) => {
          return page as SchedulePageLabels
        })
        if (!(this as any).page) {
          this.loading = false
        } else {
          //@ts-ignore
          this.emitResizeEvent()
          this.loading = false
        }
        if (!this.provider) {
          this.$router.push({ path: (this as any).localizedRoute })
        }
      },
      replaceRoute(route: {name: string, path: string}): void {
        this.$router.replace({ name: route.name, path: `${this.localizedRoute}/${route.path}` })
          .catch(err => err)
      },
      scrollIntoFocus(): void {
        const scheduleNav = this.$el.querySelector('#schedule_nav_scroll_anchor') as Element
        if ((isInViewport(scheduleNav) && !this.framed) || !scheduleNav) return
        scheduleNav.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        })
      },
      setPatientIndex(index: number): void {
        this.currentPatientIndex = index 
      },
      removeLeadingZero
    },
    async created(): Promise<void> {
      await this.loadPageLabels()
    },
    mounted() {
      eventBus.$on('scrollScheduleNavIntoFocus', () => {
        this.scrollIntoFocus()
      })
    },
    beforeDestroy() {
      eventBus.$off('scrollScheduleNavIntoFocus')
    },
    beforeRouteEnter(to, from, next) {
      //force scroll-to top behavior only if we come to scheduling from an outside route
      if (!from.meta.scheduleChild) {
        try {
          window.scrollTo(0,0) 
          window.parent.parent.scrollTo(0,0)
        } catch {
          parent.postMessage('snap_to_top', '*')
        }
      }
      next()
    }
  })
</script>
