<template>
  <div
    class="font-size-select"
    :style="[globalTheme.borderColor, globalTheme.color]">
    <button
      v-for="(size, i) in fontSizes"
      :key="i"
      :class="{ selected: selectedFontSize === size }"
      class="simple"
      :style="globalTheme.color"
      :aria-label="`select ${size} font size`"
      @click="changeFontSize(size)"
    ><span>A</span></button>
  </div>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  export default defineComponent({
    props: {
      selectedFontSize: {
        required: true,
        type: String
      },
    },
    data() {
      return {
        fontSizes: ['small', 'medium', 'large']
      }
    },
    methods: {
      changeFontSize(size): void {
        this.$emit('fontSizeSelected', size)
      }
    }
  })
</script>

<style lang="scss" scoped>
  .font-size-select span:hover {
    cursor: pointer;
  }
</style>
