<template>
  <ValidationObserver
    ref="observer"
    class="search-by-location"
    column="xs-12 s-12">
    <flash-notification v-if="serviceErrorOccurred">
      {{section.failedToFetchNetworkOptionsMessage}}
    </flash-notification>
    <form
      v-else
      grid="row wrap bottom "
      class="centerItems"
      @submit.prevent="submit"
    >
      <honey-pot @honeyPotUniqueId="honeyPotUniqueId = $event" />
      <div
        v-if="(!hideNetworkDropdown && !searchByCounty)"
        column="xs-12 s-12 m-8 l-3" 
        class="_mbs">
        <form-multi-select
          id="network"
          v-model="networkValue"
          :label="section.networkSelectLabel"
          :placeholder="section.networkSelectPlaceholderText"
          :validation-message="section.networkSelectValidationMessage"
          :multiple="false"
          :allow-empty="false"
          :options="networks"
          :searchable="true"
          :clear-on-select="true"
          @update:modelValue="handleNetworkInput"/>
      </div>
      <div
        v-if="geolocationAvailable && !framed"
        column="xs-12 s-12 m-6 l-4"
        class="_mbs location-column"
        :class="{'auth-user': hideNetworkDropdown,'location-column-county':searchByCounty}">
        <div :class="{'button-wrap':true,'button-wrap-search-by-county': searchByCounty}">

          <span
            class="seperator"
            :style="globalTheme.color">{{section.orSeperator}}</span>
          <button
            :disabled="gettingCoords || submittingZip || submittingCounty"
            :style="globalTheme.button"
            class="sm"
            type="button"
            @click.prevent="getUserCoords"
            @keydown.enter.prevent="getUserCoords"
          >
            <font-awesome-icon
              v-if="gettingCoords"
              icon="spinner"
              pulse
              aria-label="Loading..."
              class="_mrxs"
            />
            <target-svg
              v-else
              class="
              _mrxs"
            />
            {{ section.myLocationButton }}
          </button>
        </div>
      </div>
      <div
        v-if="searchByZip"
        column="xs-12 s-12 m-6 l-4"
        class="zip-column"
        :class="{'_mbxs': !section.hideSearchByCounty, '_mbs': section.hideSearchByCounty}"
      >
        <form-input
          id="zip"
          v-model="zipValue"
          class="_m0 with-button"
          :label="section.zipCodeLabel"
          :validations="gettingCoords ? '' : 'required|regex:^[0-9]{5}(-[0-9]{4})?$'"
          :mask="(zipValue.length > 5) ? &quot;#####-####&quot; : &quot;#####&quot;"
          :hide-validation-message="coordsRequested"
          :validation-message="section.zipCodeValidationMessage"
          @input="handleZipInput">
          <template v-slot:button>
            <button
              class="sm"
              type="submit"
              :style="globalTheme.button"
              :disabled="gettingCoords || submittingZip || submittingCounty">
              {{section.searchButtonLabel}}
            </button>
          </template>
        </form-input>
        <div v-if="!section.hideSearchByCounty">
          <a
            class="inline-caret-link reverse hide-print"
            :style="globalTheme.colorPrimary"
            @click="handleSearchByCountyClick()"
          >
            <font-awesome-icon
              :icon="['fas', 'caret-right']"
              class="caret _mrxxs" />
            {{ section.searchByCountyLinkText }}
          </a>
        </div>
      </div>
      <div
        v-if="searchByCounty"
        column="xs-12 s-12 m-8 l-5"
        class="_mbxs zip-column"
        :class="{'location-column-county-input':searchByCounty}"
      >
        <div class="form-group _m0 with-button">
          <ValidationProvider
            v-slot="{ field, errors }"
            :mode="validationMode"
            name="county-input"
            rules="required|county"
            tag="div"
          >
            <div
              class="scroll-anchor"
              :class="{'invalid-anchor' : errors.length > 0}" 
            />
            <label
              for="county"
              :class="{invalid : errors.length > 0 && !hideValidationMessage}"
              aria-live="polite"
              @click.prevent
            >
              <span class="moon _mrxxs">{{ section.countyInputLabel }}</span>
              <template v-if="!hideValidationMessage">
                <span
                  v-if="(errors.length > 0)"
                  class="lips validation"
                >* {{ countyValidationMessage || errors[0] }}</span>
              </template>
            </label>
            <div class="input-wrapper" >
              <input
                id="county-input"
                v-bind="field"
                v-model="countyValue"
                autocomplete="off"
                list="county-lists"
                name="county-input"
                :minlength="3"
                :maxlength="100"
                @input="handleFilterResults()"
                />
                <datalist id="county-lists">
                  <option v-for="(county, i) in filteredCounties" :key="i" :value="county" />
                </datalist>
              <button
                class="sm"
                type="submit"
                :style="globalTheme.button"
                :disabled="gettingCoords || submittingZip || submittingCounty"
                @click.prevent="getCountySearch"
                @keydown.enter.prevent="getCountySearch"
              >
                {{section.searchByCountyButton}}
              </button>
            </div>
          </ValidationProvider>
        </div>
        <div>
          <a
            class="inline-caret-link reverse hide-print"
            :style="globalTheme.colorPrimary"
            @click="handleSearchByZipClick()"
          >
            <font-awesome-icon
              :icon="['fas', 'caret-right']"
              class="caret _mrxxs" />
            {{ section.searchByZipLinkText }}
          </a>
        </div>
      </div>
    </form>
    <div
      grid="row wrap"
      class="additional-info-blocks">
      <div :column="section.infoContentAdditional ? 'xs-12 s-12 m-6 l-7' : 'xs-12 s-12'">
        <rich-text
          v-if="section.infoContent"
          class="bg-offwhite _pts _pbs _plm _prm content-block"
          :html="section.infoContent"
        />
      </div>
      <div
        v-if="section.infoContentAdditional" 
        column="xs-12 s-12 m-6 l-5">
        <rich-text
          class="bg-offwhite _pts _pbs _plm _prm content-block"
          :html="section.infoContentAdditional" />
      </div>
    </div>
  </ValidationObserver>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import Search from '@/mixins/Search'
  export default defineComponent({
    mixins: [Search],
    props: {
      framed: {
        required: true,
        type: Boolean
      }
    },

  })
</script>
<style scoped>
.centerItems{
  justify-content: center;

}

</style>
