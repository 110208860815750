<template>
  <component
    :is="decorative ? 'div' : 'button'"
    v-if="collapsed"
    :class="collapseColorClass"
    class="collapse-button btn"
    :aria-label="decorative ? false : 'Toggle collapse'"
    :style="colorSecondary ? globalTheme.buttonWhiteBorderedSecondary : globalTheme.button"
    @click="toggleCollapse"
  >
    <font-awesome-icon
      :icon="collapseIconName"
      :alt="decorative ? '' : 'plus icon'"
    />
  </component>
  <component
    :is="decorative ? 'div' : 'button'"
    v-else
    :class="collapseColorClass"
    class="collapse-button btn"
    :aria-label="decorative ? false : 'Toggle collapse'"
    :style="colorSecondary ? globalTheme.buttonWhiteBorderedSecondary : globalTheme.buttonWhiteBordered"
    @click="toggleCollapse"
  >
    <font-awesome-icon
      :icon="collapseIconName"
      :alt="decorative ? '' : 'minus icon'"
    />
  </component>
</template>

<script lang="ts">
   import {defineComponent} from 'vue'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

  export default defineComponent({
    name: 'collapse-button',
    components: {
      FontAwesomeIcon
    },
    props: {
      initiallyCollapsed: {
        type: Boolean,
        default: false
      },
      colorSecondary: {
        type: Boolean,
        default: false
      },
      decorative: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        collapsed: this.initiallyCollapsed as boolean
      }
    },
    computed: {
      collapseIconName(): string {
        return this.collapsed ? 'plus' : 'minus'
      },
      collapseColorClass(): string {
        if (this.colorSecondary) {
          return 'white bordered'
        }
        return this.collapsed ? 'isOpen' : 'white bordered'
      }
    },
    watch: {
      initiallyCollapsed: {
        handler(newVal: boolean) {
        this.collapsed = newVal
    },
    deep: true
  },
    },
    methods: {
      collapse(): void {
        this.collapsed = true
        this.$emit('onToggle', this.collapsed)
      },
      expand(): void {
        this.collapsed = false
        this.$emit('onToggle', this.collapsed)
      },
      toggleCollapse(): void {
        if (this.decorative) return
        this.collapsed = !(this.collapsed)
        this.$emit('onToggle', this.collapsed)
      }
    }
  })
</script>
