<template>
 <ValidationObserver
    ref="observer">
    <form
      class="patient-list-form"
      @submit.prevent="validateThenContinue">
      <template v-for="(appointment, i) in appointmentsPayload" >
        <hr
          v-if="i !== 0"
          :key="`hr_${i}`">
        <button
          v-if="i > 0"
          :key="`remove_${i}`"
          class="simple _mbxs remove-patient _caps"
          :style="globalTheme.colorError"
          @click.prevent="removePatient(appointment)">
          {{labels.removePatient}}
          <font-awesome-icon
            class="_mlxxs _mbxxs"
            icon="minus"
            aria-label="remove patient"
          />
        </button>
        <form-input
          v-if="appointment"
          :id="`name_${i}`"
          :key="`name_${i}`"
          v-model="appointment.patient.firstName"
          class="_mbs"
          validations="required"
          :label="labels.firstName"
          type="text"
          @input="deleteHistoricalAppointments(appointment)"/>
        <form-input
        v-if="appointment"
          :id="`l_name_${i}`"
          :key="`l_name_${i}`"
          v-model="appointment.patient.lastName"
          class="_mbs"
          validations="required"
          :label="labels.lastName"
          type="text"
          @input="deleteHistoricalAppointments(appointment)"/>
        <form-radio 
        v-if="appointment"
          :id="`visited_${i}`"
          :key="`visited_${i}`"
          v-model="appointment.patient.visitedBefore"
          :validations="appointment.patient.visitedBefore===null ? 'required' : ''"
          :options="choiceYesNo"
          :label="labels.visitedBefore"
          as-button
        />
        <form-radio 
        v-if="appointment"
          :id="`lenses_${i}`"
          :key="`lenses_${i}`"
          v-model="appointment.patient.contactLenses"
          :validations="appointment.patient.contactLenses===null ? 'required' : ''"
          :options="choiceYesNo"
          :label="labels.contactLenses"
          as-button
        />
        <form-radio 
          v-if="appointment.patient.contactLenses == true"
          :id="`lenses_new_wearer_${i}`"
          :key="`lenses_new_wearer_${i}`"
          v-model="appointment.patient.newWearer"
          :validations="appointment.patient.newWearer===null ? 'required' : ''"
          :options="choiceYesNo"
          :tooltip-content="labels.newContactWearerInfo"
          :label="labels.wornContactsBefore"
          as-button
        />
      </template>
      <button
        v-if="appointmentsPayload?.length < 3"
        class="simple _mbs _caps"
        :style="globalTheme.colorPrimaryDark"
        @click.prevent="addPatient">{{labels.addAnotherPatient}}
        <font-awesome-icon
          class="_mlxxs"
          icon="plus"
          aria-label="add patient"
        />
      </button>
      <button
        :style="globalTheme.button"
        type="submit"
        class="sm">{{labels.continueButton}}</button>
    </form>
  </ValidationObserver> 
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import FormInput from '@molecules/Form/FormInput.vue'
  import FormRadio from '@molecules/Form/FormRadio.vue'
  import { SchedulePageLabels, ExamInformationLabels,UserCreatedAppointment, CurrentProvider } from '@/types'
  import { createAppointment } from '@/ts/helpers/schedule.helpers.ts'
  import { unlockAppointment } from '@/ts/service-helpers/services/lock-appointment.ts'
  import { scheduleViewChildRoutes } from '@/router.ts'
  import { isMock } from '@/../env.config.ts'
  import { eventBus } from '@/main'
  import { trackSchedulingStep } from '@/ts/adobe-analytics/index'
  export default defineComponent({
    name:'examInformation',
    components: {
      FormInput,
      FormRadio
    },
    props: {
      page: {
        required: true,
        type: Object as () => SchedulePageLabels
      },
      value: {
        required: true,
        type: Array as () => UserCreatedAppointment[]
      },
      localizedRoute: {
        required: true,
        type: String
      },
      provider: {
        required: true,
        type: Object as ()=> CurrentProvider
      }
    },
    data() {
      return {
        choiceYesNo: [
          { value: true, label: (this as any).isSpanish() ? 'Si' : 'Yes' },
          { value: false, label:'No' }
        ]
      }
    },
    computed: {
      labels(): ExamInformationLabels {
        return { continueButton: this.page.continueButton, ...this.page.examInformation, ...this.page.personalInformation }
      },
      appointmentsPayload: {
        get(): UserCreatedAppointment[] {
          return this.value
        },
        set(newVal: UserCreatedAppointment[]) {
          this.$emit('update:value', newVal)
        }
      }
    },
    methods: {
      deleteHistoricalAppointments(appt:UserCreatedAppointment): void {
        appt.appointment = null
        appt.personalInformation = null
        appt.queuedAppointmentId = null
        if (appt.lockId) {
          unlockAppointment({ lockId: appt.lockId! })
          appt.lockId = null
        }
      },
      async validateThenContinue(): Promise<void> {
        
        const isValid = await (this.$refs.observer as any).validate()
        const query = this.$route.query
        if (isValid.valid) {
          const nextRoute = scheduleViewChildRoutes[1]
          this.$router.replace({ 
            name: nextRoute.name,
            path: `${this.localizedRoute}/schedule/${nextRoute.path}`,
            query: query
          })
            .then(() => {
              if (this.$route.query.isSbAppointment === 'true') {
                trackSchedulingStep('event-sb-step-exam-info-finished', this.provider)
              }
              else {
                trackSchedulingStep('event-tab-step-exam-info-finished', this.provider)
              }
            })
        } else {
          const errorEl = document.querySelector('.invalid-anchor')
          errorEl!.scrollIntoView({ behavior: 'smooth', block: 'start' })
          const firstErrorEl: HTMLInputElement|null = document.querySelector('.error')
          if (firstErrorEl) {
            setTimeout(() => {
              firstErrorEl.focus()
            }, 612)
          }
        }
      },
      addPatient(): void {
        this.appointmentsPayload.push(createAppointment(isMock ? 'Brad' : '', isMock ? 'Pitt' : ''))
        eventBus.$emit('iframeResizeEvent')
      },
      removePatient(appointment: UserCreatedAppointment): void {
        this.appointmentsPayload.splice(this.appointmentsPayload.findIndex(a => a === appointment), 1)
        eventBus.$emit('iframeResizeEvent')
      }
    },
  })
</script>
