<template>
  <a
    v-if="isInternalSchedulineLink && hasExternalStoreNumber"
    class="scheduling-link"
    :style="(isValidTierName || isDiscountOptOut) ? { backgroundColor: '#808080 !important', borderColor: '#808080 !important' } : ''"
    :href="localizedRoute"
    :aria-label="`${labels.scheduleCta} with this ${provider.businessName}`"
    @click.prevent="setProviderThenSchedule"
  >{{showScheduleAppointmentLabel}}<slot></slot>
  </a>
  <a
    v-else-if="isInternalSbSchedulineLink && hasExternalStoreNumber"
    class="scheduling-link"
    :style="(isValidTierName || isDiscountOptOut) ? { backgroundColor: '#808080 !important', borderColor: '#808080 !important' } : ''"
    :href="localizedSbRoute"
    :aria-label="`${labels.scheduleCta} with this ${provider.businessName}`"
    @click.prevent="setProviderThenSchedule"
  >{{showScheduleAppointmentLabel}}<slot></slot>
  </a>
  <a
    v-else-if="externalSchedulingLink && !isInternalSchedulineLink"
    class="scheduling-link"
    :style="(isValidTierName || isDiscountOptOut) ? { backgroundColor: '#808080 !important', borderColor: '#808080 !important' } : ''"
    :href="externalSchedulingLink"
    target="_blank"
    :aria-label="`${labels.scheduleCta} with this ${provider.businessName}`"
    @click.prevent="trackThenHandleSpecificOutBoundLink(externalSchedulingLink)">
    <span>{{showScheduleAppointmentLabel}}</span><slot></slot>
  </a>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { Provider, SearchResultCardLabels, CurrentProvider } from '@/types'
  import { getBaseUrl } from '@/site.config.ts'
  import { trackSchedulingStep } from '@/ts/adobe-analytics/index'
  import CaptureOutBoundLinks from '@mixins/CaptureOutBoundLinks'
  export default defineComponent({
    mixins: [CaptureOutBoundLinks],
    props: {
      provider: {
        required: true,
        type: Object as () => Provider
      },
      labels: {
        required: true,
        type: Object as () => SearchResultCardLabels
      },
      showPlusProviderSideBar: {
        default: false,
        type: Boolean
      },
      scheduleAppointmentLabel: {
        type: String
      }
    },
    data() {
      return {
        autoAdaptOutBoundLinks: false
      }
    },
    computed: {
      localizedRoute(): string {
        return `${getBaseUrl((this as any).$route.params)}/schedule?isSbAppointment=false`
      },
      localizedSbRoute(): string {
        return `${getBaseUrl((this as any).$route.params)}/schedule?isSbAppointment=true`
      },
      externalSchedulingLink(): string|undefined|null {
        const link = (this as any).provider.locationPreference.schedulingLink
        return link
      },
      isInternalSchedulineLink(): boolean {
        // return true
        const link = (this as any).provider.locationPreference.schedulingLink
        if (!link) return false
        return link.toLowerCase().substring(0,4) === 'tab;'
      },
      isInternalSbSchedulineLink(): boolean {
        // return true
        const link = (this as any).provider.locationPreference.schedulingLink
        if (!link) return false
        return link.toLowerCase().substring(0,4) === 'sb;'
      },
      hasExternalStoreNumber(): boolean {
        return (this as any).provider.externalStoreNumber
      },
      isValidTierName() {
        const tierName = this.provider.tierName
        return (tierName === 'Walmart' || tierName === 'Costco')
      },
      isDiscountOptOut(): boolean {
        const locPreferences = this.provider.locationPreference
        return locPreferences.discountOptOut === 'true'
      },
      showScheduleAppointmentLabel(): string {
        return this.scheduleAppointmentLabel ? this.scheduleAppointmentLabel : this.labels.scheduleCta
      }
    },
    methods: {
      setProviderThenSchedule(): void {
        const currentProvider: CurrentProvider = {
          ...(this as any).provider,
          searchedZipCode: this.$route.query.zip
        }
        this.$store.commit('currentProvider/setCurrentProvider', currentProvider)
        if (this.isInternalSbSchedulineLink && this.hasExternalStoreNumber) {
          trackSchedulingStep('event-sb-step-started', currentProvider)
          const query =  {
            isSbAppointment: 'true',
          }
          this.$router.push({
            path: this.localizedSbRoute,
            query: query
          })
          
        }
        else  {
          trackSchedulingStep('event-tab-step-started', currentProvider)
          const query =  {
            isSbAppointment: 'false',
          }
          this.$router.push({
            path: this.localizedRoute,
            query: query
          })
        }
      },
      trackThenHandleSpecificOutBoundLink(externalSchedulingLink: string) {
        (this as any).handleSpecificOutBoundLink(externalSchedulingLink, {
          ...(this as any).provider,
          searchedZipCode: this.$route.query.zip
        })
      }
    }
  })
</script>
