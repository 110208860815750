<template>
  <app-section>
    <div
      column="xs-12 s-12"
      class="_pl0 _pr0">
      <div
        :class="modifier"
        class="rich-text themed"
        :style="globalTheme.color"
        v-html="findAndReplceClientID(html)"
      ></div>
    </div>
  </app-section>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import RichTextRules from '@/mixins/RichTextRules'
  export default defineComponent({
    mixins: [RichTextRules]
  })
</script>
