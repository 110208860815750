<template>
  <app-collapsible-section class="date-picker-drawer">
    <template v-slot:header-content>
      <h5
        class="picker-title"
        :style="globalTheme.colorDark">
        <app-inline-svg
          class="_mrxs"
          :src="labels.monthlySvgIcon.url"
          :style="globalTheme.colorPrimary"/>
        {{labels.monthlyAvailability}}
      </h5>
    </template>
    <div grid="row wrap">
      <div
        v-if="labels.monthlyAvailabilityInfoContent"
        column="xs-12 s-12 xl-5">
        <div
          class="_mbxs"
          v-html="labels.monthlyAvailabilityInfoContent"></div>
      </div>
      <div :column="labels.monthlyAvailabilityInfoContent ? 'xs-12 s-12 xl-7' : 'xs-12 s-12 xl-8 +xl-2'">
        <VDatePicker expanded
        :attributes="selectedDate"
          mode="single"
          :model-value="selectedDate" 
          :key="calendarKey"
          :locale="`${$route.params.lang}-US`"
          :class="{disabled: fetchingExams}"
          :min-date="minDate"
          class="calendar-date-picker"
          color="gray"
          is-inline
          @update:model-value="newValue => selectedDate = newValue"
        /> 
      </div>
    </div>
  </app-collapsible-section>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import { ScheduleADateLabels } from '@/types'
  import 'v-calendar/style.css';
  export default defineComponent({
    components: {
  },
  data() {
    return {
      attributes: [
        {
           highlight: true,
        },
      ],
    };
  },
    props: {
      modelValue: {
        required: true,
        type: Date
      },
      minDate: {
        required: true,
        type: Date
      },
      labels: {
        required: true,
        type: Object as ()=> ScheduleADateLabels
      },
      fetchingExams: {
        type: Boolean,
        default: false
      },
      calendarKey: {
        type: Number,
        default:0
      }
    },
    computed: {
      selectedDate: {
        get(): Date {
          return this.modelValue
        },
        set(newVal: Date) {
         if (this.fetchingExams || !newVal) return;
          this.$emit('update:modelValue', newVal)
        }
      }
    }
  })
</script>
<style>
.vc-container.calendar-date-picker {
  width:100%;
}
</style>
